import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Account from 'components/Account/account';

const AccountPage: React.FC = () => {
  return (
    <Layout page='account'>
      <SEO title="Your Account" />
      <Account />
    </Layout>
  );
};

export default AccountPage;
